"use client";
import { Box } from "@mui/material";
import dynamic from "next/dynamic";

const Header = dynamic(() => import("@/components/Commons/Header/Header"), {
  ssr: false,
});

const Footer = dynamic(() => import("@/components/Commons/Footer/Footer"), {
  ssr: false,
});

const HomePage = dynamic(() => import("@/components/HomePage/HomePage"), {
  ssr: false,
});

export default function BaseHome() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: "black",
      }}
    >
      <Header />
      <HomePage />
      <Footer />
    </Box>
  );
}
